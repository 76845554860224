<template>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-6 mx-auto">
        <div class="card shadow border">
          <div class="card-body d-flex flex-column align-items-center">
            <template v-if="state === 'success'">
              <h1 style="color: #63c095">
                {{ $t("PAYMENTS.PAYMENT_SUCCESSFUL") }}
              </h1>
              <i
                class="fa fa-check"
                style="color: #63c095; font-size: 3.5rem"
              ></i>
              <p class="card-text mt-5">
                {{ $t("PAYMENTS.PAYMENT_THANK_YOU") }}
              </p>
              <a href="#" class="btn btn-primary" @click="goToMyTeam">{{
                $t("PAYMENTS.PAYMENT_GO_TO_MY_TEAM")
              }}</a>
            </template>
            <template v-if="state === 'error'">
              <h1>{{ $t("PAYMENTS.PAYMENT_ERROR") }}</h1>
              <p class="card-text mt-5">
                {{ $t("PAYMENTS.PAYMENT_ERROR_MESSAGE") }}
              </p>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "../../core/services/store/breadcrumbs.module";

export default {
  name: "PaymentState",
  data: function () {
    return {
      state: "",
    };
  },

  async created() {
    this.state = this.$route.params.state;
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Payment" }]);
  },

  methods: {
    goToMyTeam: function () {
      this.$router.push({ name: "team", params: { id: ApiService.teamId } });
    },
  },
};
</script>
